<template>
  <div>
    <div v-if="selectedKey === 'key_4'">
      <key-four :status_key="'key_4'" />
    </div>
    <div v-if="selectedKey === 'key_5'">
      <key-five :status_key="'key_5'" />
    </div>
    <div v-if="selectedKey === 'key_9'">
      <key-nine :status_key="'key_9'" />
    </div>
    <div v-if="selectedKey === 'key_10'">
      <key-ten :status_key="'key_10'" />
    </div>
    <div v-if="selectedKey === 'key_19'">
      <key-common
        :key="selectedKey"
        :status_key="'key_19'"
        date_filter_key="status_date"
        date_filter_name="Returned Date"
        status_name="ReceivedToOriginWarehouse(FailedToDeliver)"
      />
    </div>
    <div v-if="selectedKey === 'key_20'">
      <key-twenty :status_key="'key_20'" />
    </div>
    <div v-if="selectedKey === 'key_22'">
      <key-common
        :key="selectedKey"
        :status_key="'key_22'"
        date_filter_key="status_date"
        date_filter_name="Returned Date"
        status_name="ReceiveFailedOrder(Walking Customer)"
      />
    </div>
    <div v-if="selectedKey === 'key_28'">
      <key-common
        :key="selectedKey"
        :status_key="'key_28'"
        date_filter_key="status_date"
        date_filter_name="Recieved Date"
        status_name="ReceivedToOriginWarehouse(InvalidDestination)"
      />
    </div>
    <div v-if="selectedKey === 'key_29'">
      <key-twenty-nine :status_key="'key_29'" />
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/extensions,camelcase
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import KeyFour from '../../components/status-flow/KeyFour.vue'
import KeyFive from '../../components/status-flow/KeyFive.vue'
import KeyNine from '../../components/status-flow/KeyNine.vue'
import KeyTen from '../../components/status-flow/KeyTen.vue'
import KeyTwentyNine from '../../components/status-flow/KeyTwentyNine.vue'
import KeyTwenty from '../../components/status-flow/KeyTwenty.vue'
import KeyCommon from '../../components/status-flow/KeyCommon.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    KeyFour,
    KeyFive,
    KeyNine,
    KeyTen,
    KeyTwentyNine,
    KeyTwenty,
    KeyCommon,
  },
  data() {
    return {
      selectedKey: '',
    }
  },
  mounted() {
    this.getKey()
  },
  methods: {
    getKey() {
      this.selectedKey = this.$route.params.key
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select';
</style>
